<script setup lang="ts">
/**
 * @author Irakli Kandelaki, Luka Tchigladze
 */

/** IMPORTS */
import { ref } from "vue";

import { useMainStore } from "@/store/main/index";
const DownIcon = defineAsyncComponent(
  () => import("./SVG/DownIcon.vue")
);
const Picture = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Picture.vue")
);

/** IMPORT TYPES */
import type { Ref } from "vue";
import type { SecondaryDataInterface } from "@/types/ContentType";
/** PROPS */
defineProps<{
  contentData: SecondaryDataInterface | undefined;
}>();


/** IMPORT STORE */
const mainStore = useMainStore();

/** REACTIVE DATA */
const innerEl: Ref<HTMLElement | undefined> = ref();

const videoIsVisible: Ref<boolean> = ref(false);

/** METHODS */
const getEmbedHTML = (html: string): string => {
  if (typeof document == "undefined") return ""

  const div = document.createElement("div");
  div.innerHTML = html;

  const iframe = div.querySelector("iframe");
  if (iframe) {
    iframe.src = iframe.src + "&controls=0&muted=1";
  }

  return div.innerHTML
};

const showVideo = () => {
  if (!videoIsVisible.value) {
    videoIsVisible.value = true;

    window?.removeEventListener("scroll", showVideo);
  }
};

onMounted(() => {
  window?.addEventListener("scroll", showVideo);
});
</script>

<template>
  <section class="main-video-banner hl-1-plus bg-white pos-rel ov-hidden">
    <div class="main-video-banner__header d-grid pos-rel">
      <h1 class="pos-abs uppercase no-flicker">{{ $t("gumbati") }}</h1>
      <div class="main-video-banner__header-text d-flex dir-col">
        <p class="hl-3 no-flicker">{{ contentData?.data?.list?.[0]?.teaser }}</p>
        <div class="main-video-banner__header-text-grid d-grid">
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="line-hide-mobile"></div>
      <div class="line-hide-mobile"></div>
      <div></div>
    </div>
    
    <div
      v-if="videoIsVisible"
      class="main-video-banner__inner pos-rel w-full"
      ref="innerEl"
      :style="{
        height:
          mainStore.windowHeight > innerEl?.offsetHeight! && mainStore.windowWidth >= 984
            ? '100vh'
            : 'unset',
      }"
    >
      <ClientOnly>
        <template v-if="mainStore.windowWidth <= mainStore.breakpoints.mobile">
          <!-- MOBILE COMPONENT PRIORITY -->

          <Picture
            v-if="contentData?.data?.list?.[0]?.image?.[0]?.devices?.mobile"
            :devices="contentData?.data?.list?.[0]?.image?.[0]?.devices"
            :alt="`${contentData?.data?.list?.[0]?.title} ${contentData?.data?.list?.[0].teaser}`"
            imgClass="img-absolute"
          />

          <div
            v-else-if="contentData?.data?.list[0]?.embed"
            class="main-video-banner__embed w-full h-full pos-abs"
            v-html="getEmbedHTML(contentData?.data?.list[0]?.embed)"
          ></div>

          <video
            v-else-if="contentData?.data?.list[0]?.video?.videoFile?.url"
            class="img-absolute"
            muted
            autoplay
            loop
            preload="none" 
            playsinline
            :poster="contentData?.data?.list?.[0]?.image?.[0]?.url"
          >
            <source
              :src="contentData?.data?.list[0]?.video_mobile?.videoFile?.url"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </template>
        <template v-else>
          <!-- DESKTOP COMPONENT PRIORITY -->

          <div
            v-if="contentData?.data?.list[0]?.embed"
            class="main-video-banner__embed w-full h-full pos-abs"
            v-html="getEmbedHTML(contentData?.data?.list[0]?.embed)"
          ></div>

          <video
            v-else-if="contentData?.data?.list[0]?.video?.videoFile?.url"
            class="img-absolute"
            muted
            autoplay
            loop
            preload="none" 
            playsinline
            :poster="contentData?.data?.list?.[0]?.image?.[0]?.url"
          >
            <source
              :src="contentData?.data?.list[0]?.video?.videoFile?.url"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <Picture
            v-else-if="contentData?.data?.list?.[0]?.image?.[0]?.devices?.desktop"
            :devices="contentData?.data?.list?.[0]?.image?.[0]?.devices"
            :alt="`${contentData?.data?.list?.[0]?.title} ${contentData?.data?.list?.[0].teaser}`"
            imgClass="img-absolute"
          />
        </template>
      </ClientOnly>
      <div class="main-video-banner__overlay pos-abs w-full h-full"></div>
      <div class="main-video-banner__text-block pos-abs d-flex dir-col">
        <h2 class="no-texttype c-black pos-rel uppercase no-flicker">
          {{ contentData?.data?.list?.[0]?.title }}
        </h2>
        <h2 class="no-texttype c-white pos-rel uppercase no-flicker">
          {{ contentData?.data?.list?.[0]?.teaser_2 }}
        </h2>
        <h3 class="no-texttype c-white pos-re no-flicker">
          {{ contentData?.data?.list?.[0]?.sub_title }}
        </h3>
      </div>

      <div class="main-video-banner__notice-text pos-abs c-white uppercase no-flicker d-flex">
        {{ $t("scroll down") }}
        <div class="main-video-banner__notice-icon">
          <DownIcon class="icon-animation" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.main-video-banner {
  &__header {
    min-height: 970px;
    grid-template-columns: 50% 19.53125% 19.53125% 10.78125%;

    @include maxq(desktop-2k) {
      min-height: 650px;
      grid-template-columns: 50% 21.25% 21.25% 7.222222222222221%;
    }

    @include maxq(desktop-lg) {
      min-height: 390px;
      grid-template-columns: 50% 39.22764227642276% 10.569105691056912%;
    }

    @include maxq(desktop) {
      min-height: 360px;
      grid-template-columns: 50% 21.25% 21.25%;
    }

    @include maxq(tablet) {
      min-height: 402px;
      grid-template-columns: 50% 39.22764227642276%;
    }

    @include maxq(mobile) {
      min-height: 230px;
      grid-template-columns: 100%;
    }

    @include maxq(mobile-sm) {
      display: flex;
      flex-direction: column;
      min-height: 390px;
    }

    @include maxq(mobile-xs) {
      min-height: 500px;
    }

    & > div {
      border-right: $brightGray 1px solid;
      z-index: 2;

      &.line-hide-mobile {
        @include maxq(mobile) {
          display: none;
        }
      }
    }

    &-text {
      padding-top: 185px;
      border-bottom: $brightGray 1px solid;

      @include maxq(desktop-2k) {
        padding-top: 124px;
      }

      @include maxq(desktop-lg) {
        padding-top: 94px;
      }

      @include maxq(mobile) {
        padding-top: 62px;
      }

      p {
        @include weight(400);
        width: 72.03125%;
        font-size: 42px;
        line-height: 66px;
        margin-bottom: 76px;
        margin-left: 96px;
        transform: translateZ(0);

        @include maxq(desktop-2k) {
          width: 66.875%;
          font-size: 26px;
          line-height: 44px;
          margin-bottom: 50px;
          margin-left: 64px;
        }

        @include maxq(desktop-lg) {
          width: 68.40458811261732%;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 39px;
          margin-left: 48px;
        }

        @include maxq(desktop) {
          width: 91.11111111111111%;
        }

        @include maxq(desktop-sm) {
          width: 84.61538461538461%;
        }

        @include maxq(tablet) {
          width: 84.5528455284553%;
          margin-right: 28px;
        }

        @include maxq(mobile) {
          font-size: 14px;
          line-height: 22px;
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
          padding-left: 48px;
          padding-right: 48px;
        }

        @include maxq(mobile-sm) {
          font-size: 18px;
          line-height: 30px;
          padding-left: 24px;
          padding-right: 24px;
        }
      }

      &-grid {
        grid-template-columns: 21.5625% 39.0625% 39.0625%;
        height: 307px;
        border-top: $brightGray 1px solid;

        @include maxq(desktop-2k) {
          height: 205px;
        }

        @include maxq(desktop-lg) {
          height: 155px;
        }

        @include maxq(desktop) {
          grid-template-columns: 14.444444444444443% 42.638888888888886%;
          height: 127px;
        }

        @include maxq(tablet) {
          grid-template-columns: 21.5625%;
          height: 100px;
        }

        @include maxq(mobile) {
          grid-template-columns: 50%;
          height: 62px;
          display: none;
        }

        div {
          border-right: $brightGray 1px solid;
        }
      }
    }

    h1 {
      top: 0;
      left: 44.40104166666667%;
      font-size: 700px;
      line-height: 854px;
      color: #f3f3f3;
      @include weight(900);
      // z-index: -1;

      @include maxq(desktop-2k) {
        font-size: 476px;
        line-height: 580px;
        left: 43.75%;
      }

      @include maxq(desktop-lg) {
        font-size: 364px;
        line-height: 444px;
        left: 43.645833333333336%;
      }

      @include maxq(desktop) {
        font-size: 268px;
        line-height: 326px;
        left: 50.55555555555556%;
        top: 73px;
      }

      @include maxq(tablet) {
        font-size: 164px;
        line-height: 200px;
        left: 51.21951219512195%;
        top: 136px;
      }

      @include maxq(mobile) {
        display: none;
      }
    }
  }

  &__embed {
    top: 0;
    left: 0;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__inner {
    padding-top: 56.2499999999%;
    z-index: 2;

    @include maxq(mobile) {
      padding-top: 56.25%;
      min-height: 180px;
    }
  }

  &__overlay {
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(26, 26, 26, 0.24), rgba(26, 26, 26, 0.24));
  }

  &__text-block {
    top: -210px;
    left: 0;
    padding-left: 98px;
    width: 68.33333333333333%;
    z-index: 4;

    @include maxq(desktop-2k) {
      top: -150px;
      padding-left: 63px;
      width: 68.4765625%;
    }

    @include maxq(desktop-lg) {
      top: -108px;
      padding-left: 48px;
      width: 67.5%;
    }

    @include maxq(desktop) {
      top: -113px;
      width: 73.125%;
    }

    @include maxq(desktop-sm) {
      top: -105px;
    }

    @include maxq(tablet) {
      top: -90px;
      width: 82.21544715447155%;
    }

    @include maxq(mobile) {
      top: -45px;
      width: 100%;
      padding: 0 48px;
    }

    @include maxq(mobile-sm) {
      top: -27px;
      padding: 0 34px;
      min-height: 200px;
    }

    h2 {
      font-size: 248px;
      line-height: 263px;
      @include weight(900);

      @include maxq(desktop-2k) {
        font-size: 166px;
        line-height: 190px;
      }

      @include maxq(desktop-lg) {
        font-size: 124px;
        line-height: 132px;
      }

      @include maxq(desktop) {
        line-height: 140px;
      }

      @include maxq(desktop-sm) {
        font-size: 116px;
        line-height: 132px;
      }

      @include maxq(tablet) {
        font-size: 100px;
        line-height: 108px;
      }

      @include maxq(mobile) {
        font-size: 48px;
        line-height: 56px;
      }

      @include maxq(mobile-sm) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    h3 {
      font-family: Hessthicc;
      font-size: 108px;
      line-height: 160px;
      font-weight: normal;
      margin-top: 16px;

      @include maxq(desktop-2k) {
        font-size: 72px;
        line-height: 64px;
      }

      @include maxq(desktop-lg) {
        font-size: 56px;
        line-height: 64px;
      }

      @include maxq(tablet) {
        font-size: 32px;
        line-height: 40px;
      }

      @include maxq(mobile) {
        font-size: 24px;
        margin-top: 0;
      }

      @include maxq(mobile-sm) {
        min-height: 40px;
        font-size: 15px;
      }
    }
  }

  &__notice-icon {
    margin-left: 10px;

    @keyframes icon-animation {
      0% {
        opacity: 0.5;
        transform: translateY(-50%);
      }

      40% {
        transform: translateY(0%);
        opacity: 1;
      }

      50% {
        transform: translateY(0%);
        opacity: 1;
      }

      80% {
        opacity: 0.5;
      }

      100% {
        transform: translateY(40%);
        opacity: 0;
      }
    }

    @include maxq(desktop-lg) {
      @keyframes icon-animation {
        0% {
          opacity: 0.5;
          transform: translateY(-50%);
        }

        40% {
          transform: translateY(10%);
          opacity: 1;
        }

        50% {
          transform: translateY(10%);
          opacity: 1;
        }

        80% {
          opacity: 0.5;
        }

        100% {
          transform: translateY(40%);
          opacity: 0;
        }
      }
    }

    .icon-animation {
      display: flex;
      width: 50px;
      height: 50px;

      @media (max-width: 3839px) and (min-width: 3000px) {
        width: 35px !important;
        height: 35px !important;
      }

      @include maxq(desktop-2k) {
        width: 30px;
        height: 30px;
      }

      @include maxq(desktop-lg) {
        width: 20px;
        height: 20px;
      }

      @include maxq(desktop) {
        width: 17.5px;
        height: 17.5px;
      }

      animation: icon-animation 1.5s infinite;
    }
  }

  &__notice-text {
    line-height: 44px;
    font-size: 40px;
    top: 80px;
    right: 96px;

    @include maxq(desktop-2k) {
      line-height: 30px;
      font-size: 26px;
      top: 70px;
      right: 64px;
    }

    @include maxq(desktop-lg) {
      font-size: 14px;
      line-height: 24px;
      top: 48px;
      right: 70px;
    }

    @include maxq(tablet) {
      top: 32px;
      right: 54px;
    }

    @include maxq(mobile) {
      display: none;
    }
  }
}

@include locale {
  .main-video-banner {
    h3 {
      font-family: Galaktioni;
    }
  }
}
</style>
